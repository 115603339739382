import { useCallback, useEffect, useState } from 'react';
import './index.css';
import EventCard from '../Widgets/EventCard/EventCard';
import PWAMobile from '../../assets/pwa_mobile.png';
import { useHomeStore } from '../../store/home';

const HomePage = (): JSX.Element => {
  const { hotEvents, newEvents, upcomingEvents, pastEvents, initData } = useHomeStore();

  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  const changeCurrentTabIndex = (index: number) => {
    setCurrentTabIndex(index);
  };

  const init = useCallback(() => {
    initData();
  }, [initData]);

  useEffect(() => {
    init();
    return () => {};
  }, [init]);

  return (
    <>
      <div className="home">
        <div
          className="pwa-mobile"
          onClick={() => {
            window.open('https://www.youtube.com/shorts/CmA721h2VXI', '_blank');
          }}
        >
          <img src={PWAMobile} alt="PWA" />
        </div>
        <div className="home-tab-bar">
          <div
            className={currentTabIndex === 0 ? 'home-tab-bar-item active' : 'home-tab-bar-item'}
            onClick={() => changeCurrentTabIndex(0)}
          >
            <span>Hot</span>
            <span className="home-tab-bar-item-dot"></span>
          </div>
          <div
            className={currentTabIndex === 1 ? 'home-tab-bar-item active' : 'home-tab-bar-item'}
            onClick={() => changeCurrentTabIndex(1)}
          >
            <span>New</span>
            <span className="home-tab-bar-item-dot"></span>
          </div>
          <div
            className={currentTabIndex === 2 ? 'home-tab-bar-item active' : 'home-tab-bar-item'}
            onClick={() => changeCurrentTabIndex(2)}
          >
            <span>Upcoming</span>
            <span className="home-tab-bar-item-dot"></span>
          </div>
        </div>
        <div className="home-main-event event-grid">
          {currentTabIndex === 0
            ? hotEvents.map((e) => <EventCard key={`hot-event-${e.ID}`} event={e} />)
            : currentTabIndex === 1
            ? newEvents.map((e) => <EventCard key={`new-event-${e.ID}`} event={e} />)
            : upcomingEvents.map((e) => <EventCard key={`upcoming-event-${e.ID}`} event={e} />)}
        </div>
        <div className="home-title">
          <span className="home-title-text">Past Event</span>
          <div className="home-title-split" />
        </div>
        <div className="home-past-event event-grid">
          {pastEvents.map((e) => (
            <EventCard key={`past-event-${e.ID}`} event={e} />
          ))}
        </div>
      </div>
    </>
  );
};

export default HomePage;
