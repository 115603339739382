import { Modal } from '@mui/material';
import Logo from '../../assets/logo.png';
import CloseIcon from '../../assets/close.png';
import LoadingIcon from '../../assets/loading.png';
import SuccessIcon from '../../assets/success.png';
import './index.css';
import { Outlet } from 'react-router-dom';
import { useRef, useState } from 'react';
import { importLuma, uploadEvent } from '../../utils/request';

const Layout = (): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [canImport, setCanImport] = useState<boolean>(false);
  const [isImport, setIsImport] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const inputRef = useRef(null);

  const openModal = () => {
    setCanImport(false);
    setIsImport(false);
    setIsSuccess(false);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onSubmit = async () => {
    if (!canImport) {
      return;
    }
    try {
      setIsImport(true);
      // @ts-ignore
      const url = inputRef.current.value;
      const { data } = await importLuma(url);
      await uploadEvent(data.title, data.image, url, data.start_time, data.end_time, data.address);
      setIsImport(false);
      setIsSuccess(true);
      setTimeout(() => {
        setIsModalOpen(false);
      }, 1000);
    } catch (error) {
      console.log('%c⧭', 'color: #e50000', error);
      setIsImport(false);
    }
  };

  return (
    <>
      <div className="layout">
        <header className="header">
          <div className="appbar">
            <div className="appbar-info">
              <img className="appbar-info-logo" src={Logo} alt="logo" />
              <span className="appbar-info-slogen">Aggregator of web3 event</span>
            </div>
            <div className="appbar-submit" onClick={openModal}>
              Submit Event
            </div>
          </div>
          <div className="appbar-mobile">
            <div className="appbar-mobile-main">
              <img className="appbar-mobile-main-logo" src={Logo} alt="logo" />
              <div className="appbar-mobile-main-submit" onClick={openModal}>
                Submit Event
              </div>
            </div>
            <div className="appbar-mobile-slogen">Aggregator of web3 event</div>
          </div>
        </header>
        <div className="component">
          <Outlet />
        </div>
        <Modal open={isModalOpen} aria-describedby="modal-modal-description" disableAutoFocus>
          <div className="submit-modal-container">
            <div className="submit-modal-title">
              <div className="submit-modal-title-text">Submit Event</div>
              <div className="submit-modal-title-icon" onClick={closeModal}>
                <img src={CloseIcon} alt="close" />
              </div>
            </div>
            <div className="submit-modal-input">
              <div className="submit-modal-input-wrap">
                <input
                  ref={inputRef}
                  type="url"
                  id="url-input"
                  placeholder="Enter luma link"
                  onChange={() => {
                    // @ts-ignore
                    if (inputRef.current && inputRef.current.value.length > 0) {
                      setCanImport(true);
                    } else {
                      setCanImport(false);
                    }
                  }}
                />
              </div>
              <div className={`submit-modal-input-submit ${canImport ? '' : 'disable'}`} onClick={onSubmit}>
                {isImport ? (
                  <img
                    className="loading-icon"
                    style={{ width: '24px', height: '24px', display: 'block' }}
                    src={LoadingIcon}
                    alt="loading"
                  />
                ) : isSuccess ? (
                  <img style={{ width: '24px', height: '24px', display: 'block' }} src={SuccessIcon} alt="success" />
                ) : (
                  'Submit'
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Layout;
