import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const eventTimeFormater = (startTime: string, endTime: string) => {
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);
  return `${dayjs.utc(startDate).format('MMM DD, YYYY')} - ${dayjs.utc(endDate).format('MMM DD, YYYY')}    ${dayjs
    .utc(startDate)
    .format('HH:mm')} - ${dayjs.utc(endDate).format('HH:mm')} GMT+00:00`;
};
