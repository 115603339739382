import { create } from 'zustand';
import { Event } from '../models/event';
import { searchEvent } from '../utils/request';

interface HomeState {
  hotEvents: Event[];
  newEvents: Event[];
  upcomingEvents: Event[];
  pastEvents: Event[];
  initData: () => void;
}

export const useHomeStore = create<HomeState>((set) => ({
  hotEvents: [],
  newEvents: [],
  upcomingEvents: [],
  pastEvents: [],
  initData: async () => {
    try {
      const { data }: { data: Event[] } = await searchEvent();
      set({
        hotEvents: data,
        newEvents: data,
        upcomingEvents: data,
        pastEvents: data,
      });
    } catch (error) {
      console.log('%c⧭', 'color: #00e600', error);
    }
  },
}));
