import { Route, Routes } from 'react-router-dom';
import HomePage from './pages/Home/Home';
import NoMatchPage from './pages/NoMatch/NoMatch';
import Layout from './pages/Layout/Layout';
import 'normalize.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="*" element={<NoMatchPage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
