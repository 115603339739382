import './index.css';

import TimeIcon from '../../../assets/time.png';
import PlaceIcon from '../../../assets/place.png';
import { Event } from '../../../models/event';
import { eventTimeFormater } from '../../../utils/functions';

type EventCardProps = {
  event: Event;
};

const EventCard = ({ event }: EventCardProps): JSX.Element => {
  return (
    <>
      <div
        className="event-card"
        onClick={() => {
          window.open(event.url, '_blank');
        }}
      >
        <div className="event-card-cover">
          <img src={event.image} alt="cover" loading="lazy" />
        </div>
        <div className="event-card-info">
          <div className="event-card-info-title">{event.title}</div>
          <div className="event-card-info-time">
            <img src={TimeIcon} alt="time" />
            <div className="event-card-info-time-text">{eventTimeFormater(event.start_time, event.end_time)}</div>
          </div>
          <div className="event-card-info-place">
            <img src={PlaceIcon} alt="time" />
            <div className="event-card-info-place-text">{event.address}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventCard;
