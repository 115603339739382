import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

const baseURL = 'https://api.join3.org';
const timeout = 30000;

const service: AxiosInstance = axios.create({
  timeout,
  baseURL,
});

service.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem('app_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: any) => {
    return Promise.reject();
  }
);

service.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.headers.authorization) {
      localStorage.setItem('app_token', response.headers.authorization);
    } else {
      if (response.data && response.data.token) {
        localStorage.setItem('app_token', response.data.token);
      }
    }

    if (response.status === 200) {
      return response;
    } else {
      return response;
    }
  },
  // 请求失败
  (error: any) => {
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      return Promise.reject(response.data);
    } else {
      console.error('error');
    }
  }
);

export const searchEvent = async () => {
  return await service.get('/api/events');
};

export const uploadEvent = async (
  title: string,
  image: string,
  url: string,
  start_time: string,
  end_time: string,
  address: string
) => {
  return await service.post('/api/events', {
    title,
    image,
    url,
    start_time,
    end_time,
    address,
  });
};

export const importLuma = async (url: string) => {
  return await service.post('https://api.join3.org/spider/api1/luma', { url });
};
